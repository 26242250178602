import $ from 'jquery';
import wowjs from 'wowjs';
import Swiper from 'swiper';
require('./deps/jquery.parallax-scroll/jquery.parallax-scroll.js');

var FUSIONARY = {};

FUSIONARY.initialize = {

    init: function () {
        FUSIONARY.initialize.general();
        FUSIONARY.initialize.tab();
        FUSIONARY.initialize.sectionSwitch();
        // FUSIONARY.initialize.countUp();
        FUSIONARY.initialize.swiperSlider();
        FUSIONARY.initialize.contactButton();
    },

    general: function () {
        var wow = new wowjs.WOW({mobile: false});
        wow.init();
    },

    /*====================================*/
    /*=           Swiper Slider          =*/
    /*====================================*/

    swiperSlider: function () {
        $('.swiper-container').each(function() {
            var $this = $(this),
                perpage = $this.data('perpage') || 1,
                loop = $this.data('loop') || true,
                speed = $this.data('speed') || 700,
                autoplay = $this.data('autoplay'),
                slidegroup = $this.data('slidegroup') || 1,
                space = $this.data('space') || 0,
                direction = $this.data('direction') || 'horizontal',
                breakpoints = $this.data('breakpoints'),
                interactive = $this.data('interactive');

            var swiper = new Swiper($this, {
                slidesPerView: perpage,
                spaceBetween: space,
                slidesPerGroup: slidegroup,
                slidesPerGroup: 1,
                grabCursor: true,
                loop: loop,
                centeredSlides: true,
                speed: speed,
                direction: direction,
                breakpoints: breakpoints,
                watchSlidesVisibility: true,
                slideVisibleClass: 'swiper-slide-visible',
                autoplay: autoplay ? {delay: autoplay, disableOnInteraction: false} : false,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }
            });

            if (interactive) {
                $this.on('mouseover', function () {
                    swiper.autoplay.stop();
                });
    
                $this.on('mouseout', function () {
                    swiper.autoplay.start();
                });
            }
        });
    },

    /*=====================================*/
    /*=           Section Switch          =*/
    /*=====================================*/

    sectionSwitch: function () {
        $('[data-action="scroll"]').on('click', function() {
            var target = $(this.hash);
            if (target.length > 0) {
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                $('html,body').animate({
                    scrollTop: target.offset().top
                }, 1000);
                return false;
            }
        });
    },

    /*==============================*/
    /*=           Tabs          =*/
    /*==============================*/
    tab: function () {
        var tabItems = $('.tabs-nav .tabs-nav-item'),
            tabContentWrapper = $('.tabs-content');
        
        tabItems.filter('.active').find('.content').show();
        tabContentWrapper.find('.active').show();

        tabItems.on('click', function (e) {
            var selectedItem = $(this);
            if (!selectedItem.hasClass('active')) {
                var selectedTab = selectedItem.data('content'),
                    selectedContent = tabContentWrapper.find('[data-content="' + selectedTab + '"]');

                selectedItem.siblings().removeClass('active').find('.content').slideUp(300);
                selectedItem.addClass('active').find('.content').slideDown(300);

                selectedContent.show().siblings().hide().removeClass('active');
                setTimeout(function () {
                    selectedContent.addClass('active');
                }, 0);
            }
        });
    },

    contactButton: function () {
        drift.on('ready', function (api) {
            $('#contactButton').on('click', function () {
                api.openChat();
            });
        });
    }
};

FUSIONARY.documentOnReady = function() {
    FUSIONARY.initialize.init();
};

FUSIONARY.documentOnLoad = function() {
    FUSIONARY.documentOnScroll();
    $(".page-loader").fadeOut("slow");
};

FUSIONARY.documentOnScroll = function() {
    var scrollTop = $(window).scrollTop();

    if (scrollTop >= 30) {
        if ($(".site-header.header_trans-fixed").length) {
            $(".site-header.header_trans-fixed").addClass("pix-header-fixed");
            $(".sticky-logo, .header-button-scroll").show();
            $(".main-logo, .header-button-default").hide();
        }
    } else {
        if ($(".site-header.header_trans-fixed").length) {
            $(".site-header.header_trans-fixed").removeClass("pix-header-fixed");
            $(".sticky-logo, .header-button-scroll").hide();
            $(".main-logo, .header-button-default").show()
        }
    }
};

// Initialize Functions
$(document).ready(FUSIONARY.documentOnReady);
$(window).on('load', FUSIONARY.documentOnLoad);
$(window).on('scroll', FUSIONARY.documentOnScroll);